import React, { useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import queryString from 'query-string'
import { createClient } from 'newt-client-js'
import { BlogArticleData, BlogTagDataList, BlogArticleDataList, BlogTagData } from '../../types/blog'
import BlogArticle from '../../templates/blog-articles'

const PreviewBlog = ({ location }: PageProps) => {
  const { contentId, secret } = queryString.parse(location.search)
  const [dataArticle, setDataArticle] = useState<BlogArticleData | null>(null)
  const [dataArticles, setDataArticles] = useState<BlogArticleDataList | null>(null)
  const [dataTag, setDataTag] = useState<BlogTagDataList | null>(null)

  if (typeof contentId !== 'string' || secret !== 'blocks-service-site') {
    return null
  }

  const previewToken = process.env.GATSBY_NEWT_API_TOKEN
  if (!previewToken) throw new Error('please set GATSBY_NEWT_API_TOKEN')

  const client = createClient({
    spaceUid: 'plaid',
    token: previewToken,
    apiType: 'api',
  })

  const getContent = async () => {
    const { total, items: articles } = await client.getContents<BlogArticleData>({
      appUid: 'blocks-service-site',
      modelUid: 'blog-article',
      query: { limit: 1000 },
    })
    const articleEdges = articles.map((article) => {
      return { node: article }
    })
    const allNewtBlogArticle = { totalCount: total, edges: articleEdges }
    setDataArticles(allNewtBlogArticle)

    const dataArticle = await client.getContent<BlogArticleData>({
      appUid: 'blocks-service-site',
      modelUid: 'blog-article',
      contentId,
    })
    // sectionの整形
    dataArticle.section.forEach((section) => {
      if (section.type === 'MARKDOWN') {
        section.markdown = section.data
      } else if (section.type === 'COLUMN') {
        section.column = section.data
      } else if (section.type === 'KNOWLEDGE') {
        section.knowledge = section.data
      }
      delete section.data
    })

    // relatedArticleの追加
    const sourceTagIds = dataArticle.tags.map((tag) => tag._id)
    const relatedArticles = []
    const MAX_NUM_OF_RELATED_ARTICLES = 3
    for (const article of articles) {
      if (article._id === dataArticle._id) continue
      const tagIds = article.tags.map((tag) => tag._id)
      const allTagIds = [...tagIds, ...sourceTagIds]
      const allTagSet = new Set(allTagIds)
      if (allTagIds.length !== allTagSet.size) {
        relatedArticles.push(article)
      }
      if (relatedArticles.length >= MAX_NUM_OF_RELATED_ARTICLES) break
    }
    dataArticle.relatedArticles = relatedArticles

    setDataArticle(dataArticle)

    const { items: tags } = await client.getContents<BlogTagData>({
      appUid: 'blocks-service-site',
      modelUid: 'blog-tag',
      query: { limit: 1000 },
    })
    const tagEdges = tags.map((tag) => {
      return { node: tag }
    })
    const allNewtBlogTag = { edges: tagEdges }
    setDataTag(allNewtBlogTag)
  }

  useEffect(() => {
    getContent()
  }, [])

  if (dataArticle === null || dataArticles === null || dataTag === null) {
    return null
  }

  const pageData = { newtBlogArticle: dataArticle, allNewtBlogTag: dataTag, allNewtBlogArticle: dataArticles }

  return <BlogArticle data={pageData} />
}

export default PreviewBlog
